<template>
    <!-- {{payments.length}} -->
    <table class="table table-striped m-t-lg m-b-lg">
        <thead>
            <tr>
                <th> {{$t('manage_evidence.labels.user')}} </th>
                <th> {{$t('claims.processing.payment.vendor')}} </th>
                <th> {{$t('claims.processing.payment.amount')}} </th>
                <th> {{$t('claims.processing.payment.received')}} </th>
                <th> </th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="claim.payments.length < 1">
                <td colspan="5" class="no-data-container"> {{$t('global.no_data')}}</td>
            </tr>
            <tr v-for="payment in claim.payments" :key="`payment-${payment.id}`" style="vertical-align: middle;">
                <td> {{payment.user.email}}</td>
                <td> {{payment.payment_platform}}</td>
                <td> £{{payment.amount}} </td>
                <td> <timestamp :time="payment.received_on" :format="'dd/MM/yyyy'"> </timestamp> </td>
                <td> <button class="btn bg-danger" @click="deletePayment(payment)"> <i class="material-icons"> delete </i> {{$t('global.delete')}}</button> </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import Timestamp from '../../../../../components/Timestamp.vue';

export default {
    name: 'PaymentList',
    props: {
        claim: Object,
        client_id: Number | String
    },
    components: {Timestamp}
}
</script>

<style scoped>

</style>