<template>
  <div class="card file-manager-recent-item" :id="'evidenceasset-' + asset.id">
      <div class="card-body">
          <div class="d-flex align-items-center">
              <i class="material-icons-outlined text-danger align-middle m-r-sm">{{ icon }}</i>
              <a href="javascript:void(0);" class="file-manager-recent-item-title flex-fill">{{ asset.filename }}</a>
              <span class="p-h-sm text-muted">

                <select class="form-select">
                    <option v-for="type in types" :selected="type.id === asset.type">{{type.name}}</option>
                </select>

              </span>
              
              <a href="#" class="dropdown-toggle file-manager-recent-file-actions" :id="'asset-' + asset.id" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
              <ul class="dropdown-menu dropdown-menu-end" :aria-labelledby="'asset-' + asset.id">
                  <li><a class="dropdown-item disabled" href="javascript:void(0);">Share <span class="badge badge-info float-end">Coming Soon</span></a></li>
                  <li><a class="dropdown-item text-warning" href="javascript:void(0);" @click="download(asset)">Download</a></li>
                  <li><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></li>
              </ul>
          </div>
      </div>
  </div>
</template>

<script>
import saveAs from 'file-saver';
import ClaimService from '../../../../../services/claims.service';
import { CLAIM_EVIDENCE_FILE_TYPES } from '../../../../../constants/constants';
export default {
  name: 'ClaimEvidenceFile',
  props: {
    asset: Object,
    claim_id: Number | String
  },
  data(){
    return {
      types: CLAIM_EVIDENCE_FILE_TYPES,
      downloading: false,
      sharing: false
    }
  },
  methods: {
    download(asset){
      $(`#evidenceasset-${asset.id}`).block({ 
          message: '<div class="spinner-grow text-primary" role="status"><span class="visually-hidden">Loading...</span><div>'
      });
      ClaimService.downloadEvidence(this.$store.state.auth.user.selectedClient, this.claim_id, asset.id)
      .then(response => {
        saveAs(new Blob([response.data]), asset.filename);
      })
      .catch(error => {
        this.$error("Failed to download file!", error);
      })
      .finally(() => {
        $(`#evidenceasset-${asset.id}`).unblock();
      })
    }
  },
  computed: {
    fileType: function(){
      let parts = this.asset.filename.split('.');
      let finalPart = parts[parts.length - 1];
      return finalPart;
    },
    icon: function(){
      switch(this.fileType){
        case 'pdf':
          return 'picture_as_pdf';
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          return 'image';
        default:
          return 'description'
      }
    }
  }
}
</script>

<style scoped>

</style>