<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h5>Driver Details</h5>
        </div>
        <div class="card-body">

          <table class="table table-striped m-t-lg m-b-lg">
            <tbody>
                <tr>
                  <th>Name</th>
                  <td>{{ name }}</td>
                </tr>
                <tr>
                  <th>Address Line One</th>
                  <td>{{ address_one }}</td>
                </tr>
                <tr>
                  <th>Address Line Two</th>
                  <td>{{ address_two }}</td>
                </tr>
                <tr>
                  <th>Address Line Three</th>
                  <td>{{ address_three }}</td>
                </tr>
                <tr>
                  <th>Address Line Four</th>
                  <td>{{ address_four }}</td>
                </tr>
                <tr>
                  <th>Postcode</th>
                  <td>{{ postcode }}</td>
                </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../../../../services/auth-header';

export default {
  name: 'ClaimKeeper',
  props: {
    claim: Object,
  },
  data () {
    return {
      name: '',
      address_one: '',
      address_two: '',
      address_three: '',
      address_four: '',
      postcode: '',
    }
  },
  mounted(){
    this.getClaimKeeper();
  },
  methods: {
    getClaimKeeper(){
      axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/keeper?client_id=${this.$store.state.auth.user.selectedClient}`, {
        headers: authHeader()
      })
      .then(response => {
        this.name = (response.data.name || '-').toUpperCase();
        this.address_one = (response.data.address_one || '-').toUpperCase();
        this.address_two = (response.data.address_two || '-').toUpperCase();
        this.address_three = (response.data.address_three || '-').toUpperCase();
        this.address_four = (response.data.address_four || '-').toUpperCase();
        this.postcode = (response.data.postcode || '-').toUpperCase();
      })
      .catch(e => {
        if(e.response.status == 404){
          this.name = '-';
          this.address_one = '-';
          this.address_two = '-';
          this.address_three = '-';
          this.address_four = '-';
          this.postcode = '-';
        }else{
          this.$error(e.response.data.message || e.message, e);
        }
      });
    }
  }
}
</script>