<template>
<!-- Page to show all the claims after the evidence has been submitted. Restricted view for the clients with the basic claim information -->
  <div class="content-menu content-menu-left ps">
    <ul class="list-unstyled" role="tablist">
        <!-- <button class="nav-link" :class="{'active': tab.showing}" 
        :id="tab.name + '-tab'" type="button" role="tab" :aria-selected="tab.showing" 
        @click="selectTab(tab)">{{tab.name}} 
        <span v-if="tab.name !== 'Search'" 
        class="tab-close-icon material-icons-outlined" 
        @click.prevent="closeTab(tab)">close</span></button> -->
      <li><a href="javascript:void(0);" :class="{'active': tabs.details}" role="tab" :aria-selected="tabs.details" @click="selectTab('details')">Details</a></li>
      <div v-if="claim.reference !== undefined">
        <li><a href="javascript:void(0);" :class="{'active': tabs.notes}" role="tab" :aria-selected="tabs.notes" @click="selectTab('notes')">Notes <span v-if="claim.notes.length > 0">({{claim.notes.length}})</span></a></li>
        <li><a href="javascript:void(0);" :class="{'active': tabs.vehicle}" role="tab" :aria-selected="tabs.vehicle" @click="selectTab('vehicle')">Vehicle</a></li>
        <li><a href="javascript:void(0);" :class="{'active': tabs.evidence}" role="tab" :aria-selected="tabs.evidence" @click="selectTab('evidence')">Evidence <span v-if="claim.assets.length > 0">({{claim.assets.length}})</span></a></li>
        <li v-if="$can('claims.keeper.read') || $can('claims-process')"><a href="javascript:void(0);" :class="{'active': tabs.keeper}" role="tab" :aria-selected="tabs.keeper" @click="selectTab('keeper')">Driver</a></li>
        <li class="divider"></li>
        <li><a href="javascript:void(0);" :class="{'active': tabs.payments}" role="tab" :aria-selected="tabs.payments" @click="selectTab('payments')">Payments <span v-if="claim.payments.length > 0">({{claim.payments.length}})</span></a></li>
      </div>
      <div v-else style="width: 100%; text-align: center;">
          <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
              <span class="visually-hidden">Loading...</span>
          </div>
      </div>
    </ul>
  </div>
  <div class="content-wrapper" style="margin-bottom: 200px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="page-description d-flex align-items-center">
                    <div class="page-description-content flex-grow-1">
                        <h1 style="font-size: 29px;">{{claim.reference || ""}} </h1>
                    </div>
                    <div class="page-description-actions">
                        <span class="badge badge-style-bordered" :class="statusBadge"><span v-if="claim.status === 'Locked'" style="vertical-align:middle;margin-bottom:4px;" class="material-icons-outlined">lock</span> {{(claim.status || "loading").toUpperCase()}}</span> 
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col">

            <!-- Loading -->
            <div v-show="claim.reference === undefined" style="width: 100%; text-align: center;">
                <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            

            <div class="tab-pane fade" v-show="tabs.details" :class="{'show active': tabs.details}" id="detailsTab" role="tabpanel" aria-labelledby="details-tab">
              <!-- Type specific information here --> 
              <parking-details v-if="claim.type === 'parking'" :claim="claim" :client_id="client_id">
                <claim-details :claim="claim" :incidentTimestamp="incidentTimestamp"/>
              </parking-details>
              <driveoff-details v-if="claim.type === 'driveoff' || claim.type === 'escalated' || claim.type === 'cnafe'" :claim="claim" :client_id="client_id">
                <claim-details :claim="claim" :incidentTimestamp="incidentTimestamp"/>
              </driveoff-details>
              <cnaf-details v-if="claim.type === 'cnaf'" :claim="claim" :client_id="client_id">
                <claim-details :claim="claim" :incidentTimestamp="incidentTimestamp"/>
              </cnaf-details>
              <nmop-details v-if="claim.type === 'nmop'" :claim="claim" :client_id="client_id"> 
                <claim-details :claim="claim" :incidentTimestamp="incidentTimestamp"/>
              </nmop-details>

            </div>

            <div class="tab-pane fade" v-show="tabs.notes" :class="{'show active': tabs.notes}" role="tabpanel">
              <claim-notes :claim="claim" :client_id="client_id" @create="addNote" @delete="deleteNote"/>
            </div>
            
            <div class="tab-pane fade" v-show="tabs.vehicle" :class="{'show active': tabs.vehicle}" role="tabpanel">
              <claim-vehicle v-if="tabs.vehicle" :claim="claim" :client_id="client_id"/>
            </div>
            <div class="tab-pane fade" v-show="tabs.evidence" :class="{'show active': tabs.evidence}" role="tabpanel">
              <claim-evidence v-if="tabs.evidence" :claim="claim" :client_id="client_id"/>
            </div>

            <div class="tab-pane fade" v-show="tabs.keeper" :class="{'show active': tabs.keeper}" role="tabpanel">
              <claim-keeper v-if="tabs.keeper" :claim="claim" :client_id="client_id"/>
            </div>
            
            <div class="tab-pane fade" v-show="tabs.payments" :class="{'show active': tabs.payments}" role="tabpanel">
              <claim-payments v-if="tabs.payments" :claim="claim" :client_id="client_id"/>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import ClaimService from '../../../services/claims.service';
import Timestamp from '../../../components/Timestamp.vue';
import ClaimNotes from '../processing/claim/notes/ClaimNotes.vue';
import ParkingDetails from '../processing/claim/details/ParkingDetails.vue';
import DriveoffDetails from '../processing/claim/details/DriveoffDetails.vue';
import NmopDetails from '../processing/claim/details/NmopDetails.vue';
import ClaimDetails from '../processing/claim/ClaimDetails.vue';
import ClaimVehicle from '../processing/claim/vehicle/ClaimVehicle.vue';
import ClaimEvidence from '../processing/claim/evidence/ClaimEvidence.vue';
import ClaimPayments from '../processing/claim/payments/ClaimPayments.vue';
import CnafDetails from '../processing/claim/details/CnafDetails.vue';
import ClaimKeeper from '../processing/claim/ClaimKeeper.vue';

export default {
  name: 'Claim',
  components: {
    Timestamp,
    ParkingDetails,
    ClaimDetails,
    ClaimNotes,
    ClaimVehicle,
    DriveoffDetails,
    ClaimEvidence,
    NmopDetails,
    ClaimPayments,
    CnafDetails,
    ClaimKeeper
},
  props: {
    id: Number | String,
    client_id: Number | String
  },
  data() {
    return {
      claim: {},
      tabs: {
        details: true,
        notes: false,
        vehicle: false,
        evidence: false,
        payments: false,
        keeper: false
      },
      collision: {
        viewing: 2
      },
      viewing: {
        appeal: null
      },
      footer: {

      },
      firstLoad: true,
      accordionCollapsed: false
    }
  },
  mounted(){
    this.loadClaim();
    document.addEventListener('keydown', this.handleKeyDown)
  },
  beforeDestroy(){
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e){
      e = e || window.event;
      if(e.ctrlKey){
        switch(e.key){
          case 'ArrowRight':
          case 'ArrowDown':
            this.nextTab();
            break;
          case 'ArrowLeft':
          case 'ArrowUp':
            this.previousTab();
            break;
        }
      }
    },
    loadClaim(){
      ClaimService.getClaim(this.$store.state.auth.user.selectedClient, this.id)
      .then(response => {
        response.data.claim.payments = [];
        this.claim = response.data.claim;
        this.claim.notes.sort((a, b) => {
          return b.created_at - a.created_at
        });
        this.loadPayments();
      })
      .catch(error => {
        console.error(error);
      })
    },
    loadPayments(){
      ClaimService.getClaimPayments(this.id, this.$store.state.auth.user.selectedClient)
      .then(response => {
        this.claim.payments = response.data.payments;
      })
      .catch(error => {
        console.error(error);
      })
    },
    selectTab(tab){
      for(let t in this.tabs){
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
    },
    addNote(note){
      this.claim.notes.unshift(note);
    },
    deleteNote(note){
      this.claim.notes = this.claim.notes.filter((n) => {
        return n.id !== note.id;
      });
    },
    nextTab(){

      let tabNames = Object.keys(this.tabs);
      let currentTab = null;
      
      Object.keys(this.tabs).forEach((t) => {
        if(this.tabs[t] === true){
          currentTab = t;
        }
      });

      let index = tabNames.indexOf(currentTab);
      if(index + 1 > tabNames.length - 1){
        index = 0;
      }else{
        index++;
      }

      this.selectTab(tabNames[index]);

    },
    previousTab(){
      let tabNames = Object.keys(this.tabs);
      console.log(tabNames);
      let currentTab = null;
      
      Object.keys(this.tabs).forEach((t) => {
        if(this.tabs[t] === true){
          currentTab = t;
        }
      });

      let index = tabNames.indexOf(currentTab);
      console.log("Original Index:", index);
      if(index - 1 < 0){
        index = tabNames.length - 1;
      }else{
        index--;
      }

      console.log("Tab Name:", currentTab, "New Tab:", tabNames[index], "Index:", index)

      this.selectTab(tabNames[index]);
    }
  },
  computed: {
    claimType: function(){
      switch(this.claim.type){
        case 'driveoff':
          return 'Drive Off';
        case 'escalated':
          return 'Drive Off (No Means of Payment)';
        case 'nmop':
          return 'No Means of Payment';
        case 'parking':
          return 'Parking';
      }
    },
    statusBadge: function(){
      switch(this.claim.status){
        case 'Cancelled':
        case 'Locked':
          return 'badge-danger';
        case 'Paid':
        case 'Paid on Site':
          return 'badge-success';
        case 'Keeper Appealed':
        case 'Debt Collection':
          return 'badge-warning';
        default:
          return 'badge-primary';
      }
    },
    formattedDates: function(){
      let actualDates = this.claim.dates;
      // https://stackoverflow.com/a/43376967/2984964
      const toTitleCase = (phrase) => {
        return phrase
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };
      let formatted = [];
      for (const date in actualDates){
        if(actualDates.hasOwnProperty(date)){
            if(date.includes('date_') && actualDates[date] !== null){
              
                let name = toTitleCase(date.replace('date_', '').split('_').join(' '));
                let properDate = DateTime.fromSeconds(actualDates[date]).toFormat('dd/MM/yyyy');

                formatted.push({name: name, date: properDate});

            }
        }
      }

      return formatted;
    },
    incidentTimestamp: function(){
      if(this.claim.type !== 'parking'){
        if(this.claim.events[0] !== undefined){
          return this.claim.events[0].created_at;
        }
        if(this.claim.incident_timestamp !== null){
          return this.claim.incident_timestamp;
        }else{
          return this.claim.date_received;
        }
      }else{
        // return this.claim.events[1].created_at;
        if(this.claim.events[0] !== undefined) {
          return this.claim.events[0].created_at;
        }
        return this.claim.date_received;
      }
    }
  }
}
</script>

<style scoped>
  .claim-footer {
    position: fixed;
    bottom: 0px;
    left: 20%;
    right: 20%;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 15px;
    min-width: 60%;
    max-width: 60%;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: none;
  }

  .app-content .page-description {
    border-bottom: none;
  }

  .app-content .page-description {
    margin-bottom: 0px;
  }

  .table-heading {
    padding-top: 0px;
    font-weight: 400px;
  }

  .badge.badge-style-bordered.badge-primary {
    border-color: #FF9500;
    color:#FF9500;
    border-radius: 23px;
    font-size: 23px;
  }
</style>